/* FONTS */


/* COLOURS */


/* MIXINS */


@import "foundation/_foundation";
@import "_jquery.bxslider.scss";




* {
	box-sizing: border-box;
}

body {
	font-family: "Montserrat",'Segoe UI','Roboto','Oxygen','Ubuntu','Cantarell','Fira Sans','Droid Sans','Helvetica Neue',sans-serif;
	width: 100%;
	height: 100%;
	margin: 0;
	border: 0;
	margin: 0;
	padding: 0;
	background: #fafafa;
}


#header {
	background: #fff;
	display: block;
	height: 100px;

	h1 {
		font-size: 1.3em;
		font-weight: 500;
		text-align: center;
		line-height: 100px;
	}
	@media only screen and (max-width: 767px) {
		height: 50px;

		h1 {
			font-size: 1em;
			line-height: 50px;
		}
	}
}
#content {
	height: calc(100vh - 150px);
	position: relative;
	overflow-y: auto;
	// background: pink;

	@media only screen and (max-width: 767px) {
		height: calc(100vh - 100px);
	}
}
#footer {
	background: #fff;
	display: block;
	height: 50px;
	text-align: center;
	position: relative;
	z-index: 999;
	a {
		font-size: .7em;
		position: absolute;
		left: 0;
		right: 0;
		padding: 0 15px;
		top: 50%;
		transform: translateY(-50%);
	}
}
#slider {
	margin: 0;
	padding: 0;
	height: 100% !important;
	opacity: 0;
	transition: all .25s ease-in-out;

	&.visible {
		opacity: 1;
	}
	li {
		height: 100% !important;
		overflow-y: auto;
	}
}

.option {
	display: inline-block;
	width: 180px;
	height: auto;
	text-align: center;
	font-weight: 100;
	margin: 20px 10px;
	color: #999;
	transition: all .2s ease-in-out;
	vertical-align: top;

	.icon {
	}
	svg {
		// fill: #aaa !important;
		display: block;
		width: 80px;
		height: 80px;
		margin: 0 auto 15px;
		transition: all .2s ease-in-out;
		transform: scale(1);

	}

	&:hover {
		transform: scale(1.05);

		color: #111;
	}

	@media only screen and (max-width: 767px) {
		display: block;
		margin: 0 auto;
		font-size: .85em;
		padding: 10px;
		width: 150px;
		height: 140px;

		svg {
			width: 50px;
			height: 50px;
		}
	}
}

.back {
	display: inline-block;
	height: 145px;
	width: 30px;
	vertical-align: top;
	position: relative;
	transition: all .2s ease-in-out;
	opacity: .1;
	margin: 0 30px;

	svg {
		width: 30px;
		position: absolute;
		top: 50%;
		left: 0;
		transform: translateY(-50%);
	}
	&:hover {
		transform: scale(1.05);
		opacity: .75;
	}
}

.container {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	text-align: center;
	width: 100%;

	@media only screen and (max-width: 767px) {
		position: static;
		transform: none;

		padding: 80px 0;
	}
}

.vacationRequest {
	margin-top: 20px;
}
